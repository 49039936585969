<template>
  <div>
    <AppHeader :breadcrumbsItems="items" :title="'Descargas App Store'" />
    <v-row>
      <v-col cols="12" md="3" v-for="(app, i) in apps" :key="i">
        <AppDescarga :title="app.title" :img="app.img" :link="app.link" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppDescarga from "@/components/DownloadCard.vue";
import AppHeader from "../components/dashboard/AppHeader.vue";

export default {
  name: "DownloadView",
  components: {
    AppHeader,
    AppDescarga,
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Apple",
          disabled: false,
          link: false,
        },
      ],
      apps: [
        {
          title: "App SICS",
          img: "assets/banner.jpeg",
          link: "",
        },
        {
          title: "Axon Capture",
          img: "assets/axon.jpeg",
          link: "https://apps.apple.com/us/app/axon-capture/id680982121",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
