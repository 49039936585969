<template>
  <div>
    <v-card class="rounded-lg pr-4 pl-4 mb-4" elevation="3">
      <v-row align="center">
        <v-col cols="12" md="5" class="pa-2">
          <v-subheader
            v-bind:class="{
              't-mobile': $vuetify.breakpoint.xsOnly,
            }"
          >
            <h2>{{ title }}</h2>
            <!-- <v-btn color="success" class="rounded-lg"> Pedidos </v-btn> -->
          </v-subheader>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-sm-end pa-0">
          <v-breadcrumbs
            :items="breadcrumbsItems"
            large
            v-bind:class="{
              'p-mobile': $vuetify.breakpoint.xsOnly,
            }"
          >
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  props: {
    title: {
      type: String,
      default: "default",
    },
    breadcrumbsItems: {
      type: Array,
      default: function () {
        return [
          {
            text: "Dashboard",
            disabled: false,
            link: false,
          },
        ];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.p-mobile {
  padding: 0px 12px 18px 12px;
}
.t-mobile {
  padding-left: 0;
}
</style>
