<template>
  <v-app id="inspire" :style="{ backgroundColor: bgColor }">
    <!-- <Header v-if="loginState"/>
    <Sidebar v-if="loginState"/> -->

    <AppNavigation v-if="logged" />
    <LoginPage v-else />
    <v-main>
      <v-container v-if="logged" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

import AppNavigation from "./components/dashboard/AppNavigation.vue";
import LoginPage from "./views/LoginPage.vue";
// import {mapGetters } from "vuex"

export default {
  components: {
    AppNavigation,
    LoginPage,
  },
  data: () => ({
    bgColor: "#f6f6f6",
  }),
  computed: {
    ...mapState({
      logged: (state) => state.user.logged,
    }),
  },

  created() {
    if (this.$route.path !== "/login") this.$store.dispatch("user/checkLogin");
  },
};
</script>

<style>
@font-face {
  font-family: "MyFont";
  src: url("./assets/fonts/BrokmanTrial-Bold.otf") format("opentype");
}
</style>
