// import Vue from "vue";
import axios from "axios";

const state = () => ({
  sectoriales: [],
  destinos: [],
  jerarquias:[]
});

const mutations = {
  SET_SECTORIALES(state, sectoriales) {
    state.sectoriales = sectoriales;
  },
  SET_DESTINOS(state, destinos) {
    state.destinos = destinos;
  },
  SET_JERARQUIAS(state, jerarquias) {
    state.jerarquias = jerarquias;
  },
};

const actions = {
  async getSectoriales({ commit, state }, refresh) {
    try {
      console.log("entra");
      
      if (state.sectoriales.length !== 0 && !refresh) {
        console.log("Los sectoriales ya están cargados");
        return;
      }

      const resp = await axios.get(
        process.env.VUE_APP_BACKEND_ENVIRONMENT + "/sectoriales"
      );

      const sectoriales = resp.data.map((elem) => ({
        ...elem,
        dataText: elem.sectorial,
        dataValue: elem.id,
      }));
      console.log("Sectoriales cargados:", sectoriales);

      commit("SET_SECTORIALES", sectoriales);
    } catch (e) {
      console.error("Error al cargar los sectoriales:", e);
    }
  },

  async getDestinos({ commit, state }, refresh) {
    try {
      if (state.destinos.length !== 0 && !refresh) {
        console.log("Los destinos ya están cargados");
        return;
      }

      const resp = await axios.get(
        process.env.VUE_APP_BACKEND_ENVIRONMENT + "/destinos"
      );
    console.log("Respuesta de destinos", resp);
      const destinos = resp.data.map((elem) => ({
        ...elem,
        dataText: elem.destino,
        dataValue: elem.id,
      }));

      console.log("Destinos cargados:", destinos);
      commit("SET_DESTINOS", destinos);
    } catch (e) {
      console.error("Error al cargar los destinos:", e);
    }
  },
  async getJerarquias({ commit, state }, refresh) {
    try {
      if (state.jerarquias.length !== 0 && !refresh) {
        console.log("Los jerarquias ya están cargados");
        return;
      }

      const resp = await axios.get(
        process.env.VUE_APP_BACKEND_ENVIRONMENT + "/jerarquia"
      );
    console.log("Respuesta de jerarquias", resp);
      const jerarquias = resp.data.map((elem) => ({
        ...elem,
        dataText: elem.jerarquia,
        dataValue: elem.id,
      }));

      console.log("jerarquias cargados:", jerarquias);
      commit("SET_JERARQUIAS",  jerarquias);
    } catch (e) {
      console.error("Error al cargar los jerarquias:", e);
    }
  },
};

const getters = {
  getSectoriales: (state) => state.sectoriales,
  getDestinos: (state) => state.destinos,
  getJerarquias: (state) => state.jerarquias,
};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
