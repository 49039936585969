import Vue from "vue";
import Cookies from "js-cookie";
import router from "@/router";

const state = () => ({
  logged: false,
  userData: {},
  token: null,
});

const mutations = {
  setLogin(state, status) {
    state.logged = status;
  },
  setToken(state, token) {
    state.token = token; // Actualiza la variable de estado con el token recibido
    Cookies.set("token", token, {
      secure: true,
      sameSite: "Strict",
      expires: 7,
    }); // Guarda el token en una cookie segura
  },
  clearToken(state) {
    state.token = null;
    Cookies.remove("token");
  },
  setUserData(state, payload) {
    state.userData = payload;
    localStorage.setItem("userData", JSON.stringify(payload));
  },
};

const actions = {
  async login({ commit }, payload) {
    try {
      // Datos quemados para validar el login
      const hardcodedUser = {
        username: "admin",
        password: "123456",
        token: "fake-jwt-token",
        userData: {
          name: "Admin",
          role: "Administrator",
        },
      };

      // Verifica si las credenciales son correctas
      if (
        payload.username === hardcodedUser.username &&
        payload.password === hardcodedUser.password
      ) {
        // Establece el token y los datos del usuario
        commit("setToken", hardcodedUser.token);
        commit("setUserData", hardcodedUser.userData);
        commit("setLogin", true);

        // Redirige a la ruta raíz si no está ya allí
        if (router.currentRoute.name !== "home") {
          router.push({ name: "home" });
        }
      } else {
        // Maneja error de credenciales incorrectas
        throw new Error("Credenciales incorrectas");
      }
    } catch (error) {
      console.error(error);
      Vue.prototype.$toast.open({
        message: error.message || "Error en el login",
        position: "top-right",
        type: "error",
        duration: 3000,
      });

      throw error;
    }
  },

  checkLogin({ commit }) {
    const token = Cookies.get("token");
    commit("setLogin", !!token);
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      commit("setUserData", userData);
    }
  },

  logout({ commit }) {
    commit("clearToken");
    commit("setUserData", {});
    commit("setLogin", false);
    localStorage.removeItem("userData");
  },
};

const getters = {
  isAuthenticated: (state) => state.logged,
  getUserData: (state) => state.userData,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
