<template>
  <v-card class="mx-auto">
    <youtube
      style="overflow: hidden"
      :video-id="videoId"
      @ready="ready"
      player-width="100%"
      :iframeMode="true"
    ></youtube>

    <v-card-title class="pt-1 pb-2">
      {{ title }}
    </v-card-title>
    <v-card-text class="text--primary"> {{ text }} </v-card-text>
    <!-- <v-btn @click="enviar()">enviar</v-btn> -->
  </v-card>
</template>

<script>
import ObraSocialIntegrationService from "@/services/ObraSocialIntegrationService";
export default {
  name: "TutorialCard",
  props: {
    videoId: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "error ",
    },
    text: {
      type: String,
      default: "error ",
    },
  },
  methods: {
    enviar() {
      ObraSocialIntegrationService.getDataHamburgo("20153972")
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ready(event) {
      this.player = event.target;
    },
  },
};
</script>

<style lang="scss" scoped></style>
