<template>
  <div>
    <AppHeader :breadcrumbsItems="items" :title="'Descargas Play Store'" />
    <v-row>
      <v-col cols="12" md="3" v-for="(app, i) in apps" :key="i">
        <AppDescarga :title="app.title" :img="app.img" :link="app.link" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppDescarga from "@/components/DownloadCard.vue";
import AppHeader from "../components/dashboard/AppHeader.vue";

export default {
  name: "DownloadView",
  components: {
    AppHeader,
    AppDescarga,
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Android",
          disabled: false,
          link: false,
        },
      ],
      apps: [
        {
          title: "App SICS",
          img: "assets/banner.jpeg",
          link: "https://play.google.com/apps/internaltest/4701519778276114770",
        },
        {
          title: "Axon Capture",
          img: "assets/axon.jpeg",
          link: "https://play.google.com/store/apps/details?id=com.evidence&hl=es_BO",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
