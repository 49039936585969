import axios from "axios";

class ApiService {
    constructor(baseURL) {
        this.api = axios.create({
            baseURL: baseURL,
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    async get(endpoint, params) {
        try {
            const response = await this.api.get(endpoint, { params });
            return response.data;
        } catch (error) {
            throw new Error(`Error al realizar la solicitud: ${error.message}`);
        }
    }
}

class ObraSocialIntegrationService {
    constructor() {
        this.iosepApi = new ApiService('https://app.iosep.gob.ar/WsRest/api/Afiliados/GetAfiliadosC');
        this.hamburgoApi = new ApiService('http://hamburgo.sytes.net/hamburgoBackend/webresources/prestadores');
    }

    async getDataIosep(dni) {
        try {
            return await this.iosepApi.get('', { Dni: dni });
        } catch (error) {
            throw new Error('Error al obtener los datos del servidor de IOSEP');
        }
    }

    async getDataHamburgo(nid) {
        try {
            const params = {
                usuario: 'circulo',
                clave: 'circulo123',
                tid: '00',
                nid: nid,
                tipoRespuesta: 'JSON'
            };
            return await this.hamburgoApi.get('/consultaCoberturaUsuario', params);
        } catch (error) {
            throw new Error('Error al obtener los datos del servidor de  Hamburgo');
        }
    }
}

export default new ObraSocialIntegrationService();