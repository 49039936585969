import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import dropdownData from "./modules/dropdownData";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    dropdownData
  },
});
