<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div
          class="card-flip"
          :class="{
            rotated: this.$vuetify.breakpoint.xs,
            flipped: isFlipped,
          }"
          @click="toggleFlip"
        >
          <div class="card__container">
            <!--!! CARD FRONT -->
            <div class="card-first__container">
              <div class="header__card">
                <v-img
                  class="logo-letras-policia__card"
                  src="../assets/LOGO-PNG 1.png"
                />

                <v-img
                  class="escudo-provincia__card"
                  src="../assets/escudo.png"
                />

                <v-img
                  class="logo-secretaria__card"
                  src="../assets/LOGO-SECRETARIA-BLANCO-TRANS 1.png"
                />
              </div>
              <div class="background-photo__card">
                <div class="contain-img-policia__card">
                  <v-img
                    class="img-policia__card"
                    src="../assets/silueta.jpeg"
                  />
                </div>
              </div>
              <div class="contain-info__card">
                <div class="zona-info__card">
                  <span class="titulos-card">
                    {{ this.policeData?.nombre_completo }}
                  </span>
                  <span class="jerarquia-card">
                    {{ this.policeData?.jerarquia || "SIN DATOS" }}
                  </span>
                </div>
                <div class="zona-info__card">
                  <div class="contain-detalle-info__card">
                    <span class="titulo-detalle-info__card">ESTADO CIVIL: </span
                    ><span class="subtitulo-detalle-info__card">Sin Datos</span>
                  </div>

                  <div class="contain-detalle-info__card">
                    <span class="titulo-detalle-info__card"
                      >MATRICULA INDIVIDUAL: </span
                    ><span class="subtitulo-detalle-info__card">
                      {{ this.policeData?.dni || "SIN DATOS" }}</span
                    >
                  </div>

                  <div class="contain-detalle-info__card">
                    <span class="titulo-detalle-info__card"
                      >FECHA DE NACIMIENTO: </span
                    ><span class="subtitulo-detalle-info__card">
                      {{
                        this.policeData?.fecha_nacimiento || "SIN DATOS"
                      }}</span
                    >
                  </div>
                </div>

                <div class="zona-info__card">
                  <span class="titulos-card">
                    CREDENCIAL N°
                    {{ this.policeData?.credencial || "SIN DATOS" }}
                  </span>
                  <span class="titulos-card">
                    FECHA OTORGAMIENTO:{{ this.currentDate || "SIN DATOS" }}
                  </span>
                </div>
              </div>
            </div>

            <!--!! CARD BACK  -->
            <div class="card-first__container-footer">
              <div class="footer__card">
                <v-img
                  class="escudo-provincia-footer__card"
                  src="../assets/escudo.png"
                />

                <v-img
                  class="logo-secretaria-footer__card"
                  src="../assets/LOGO-SECRETARIA-BLANCO-TRANS 1.png"
                />
              </div>
              <div class="background-photo-footer__card">
                <div class="contain-img-policia-footer__card">
                  <v-img class="img-policia__card" src="../assets/qr.jpeg" />
                </div>
                <v-img
                  class="logo-letras-policia-footer__card"
                  src="../assets/LOGO-PNG 1.png"
                />
              </div>
              <div class="contain-img-escudo-policia-footer__card">
                <v-img src="../assets/escudo_policia.png" />
              </div>
              <div class="contain-info-footer__card">
                <div class="zona-info-footer__card">
                  <div class="contain-detalle-info-footer__card">
                    <span class="titulo-detalle-info__card">Cuerpo: </span
                    ><span class="subtitulo-detalle-info__card">{{
                      this.cuerpos[this.policeData?.cuerpo]?.cuerpo ||
                      "sin datos"
                    }}</span>
                  </div>

                  <div class="contain-detalle-info-footer__card">
                    <span class="titulo-detalle-info__card">Escalafon: </span
                    ><span class="subtitulo-detalle-info__card">
                      {{
                        this.cuerpos[this.policeData?.cuerpo]?.escalafon ||
                        "sin datos"
                      }}</span
                    >
                  </div>

                  <div class="contain-detalle-info-footer__card">
                    <span class="titulo-detalle-info__card"
                      >Grupo Sanguíneo: </span
                    ><span class="subtitulo-detalle-info__card">
                      Sin datos</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- <SnackBarCredencial v-if="this.$vuetify.breakpoint.xs" /> -->
  </div>
</template>

<script>
import moment from "moment";
// import SnackBarCredencial from "@/components/SnackBarCredencial.vue";

export default {
  name: "CredencialPolicialView",

  created() {
    this.currentDate = moment().format("DD/MM/YYYY");
  },
  data() {
    return {
      isFlipped: false,
      foto: "../assets/policiafoto.png",
      isPhone: false,
      isHorizontal: false,
      currentDate: "",
      policeData: null,
      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Credencial",
          disabled: false,
          link: false,
        },
      ],
      cuerpos: {
        "C.S.E.G.": {
          cuerpo: "Seguridad",
          escalafon: "general",
        },
        "C.T.E.C.": {
          cuerpo: "Tecnico",
          escalafon: "Comunicaciones",
        },
        "C.T.E.B.": {
          cuerpo: "Tecnico",
          escalafon: "Bombero",
        },
        "C.T.E.I.": {
          cuerpo: "Tecnico",
          escalafon: "Intendencia",
        },
        "C.T.E.M.": {
          cuerpo: "Tecnico",
          escalafon: "Maestranza",
        },
        "C.S.A.E.M.": {
          cuerpo: "Serv. Auxiliares",
          escalafon: "Maestranza",
        },
        "C.S.A.E.O.": {
          cuerpo: "Serv. Auxiliares",
          escalafon: "Oficinista",
        },
        "C.S.A.": {
          cuerpo: "Serv. Auxiliares",
          escalafon: " ",
        },
        "C.S.A.E.A.": {
          cuerpo: "Serv. Auxiliares",
          escalafon: "Antenista",
        },
        "Cpo. Profesional": {
          cuerpo: "Profesional",
          escalafon: " ",
        },
      },
    };
  },

  mounted() {
    this.policeData = JSON.parse(localStorage.getItem("userData"));
  },

  methods: {
    toggleFlip() {
      this.isFlipped = !this.isFlipped;
    },

    cuerposz() {
      //       1	C.S.E.G. cuerpo seguridad escalafon general
      // 2	C.T.E.C.    cuerpo tecnico escalafon comunicaciones
      // 3	C.T.E.B.   cuerpo tecnico escalafon bomberos
      // 4	C.T.E.I.  cuerpo tecnico escalafon intendencia
      // 5	C.T.E.M.   cuerpo tecnico escalafon maestranza
      // 6	C.S.A.E.M.   cuerpo servicio auxiliares escalafon maestranza
      // 7	C.S.A.E.O.   cuerpo servicio auxiliares escalafon oficinista
      // 8	C.S.A.       cuerpo servicio auxiliar
      // 9	C.S.A.E.A.   cuerpo servicio auxiliares escalafon antenista
      // 10	Cpo. Profesional     escalafon sin datos
    },
  },
};
</script>

<style lang="scss" scoped>
.card-flip {
  width: 560px;
  height: 350px;
  perspective: 500px;
}

.card-flip.flipped .card__container {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}
.card__container {
  transition: transform 1s;
  transform-style: preserve-3d;
}

// hasta el nuevo

.rotated {
  transform: rotate(90deg);
  position: absolute;
  top: 110px;
  right: -85px;
}

.card-first__container {
  width: 560px;
  height: 350px;
  position: absolute;
  background: white;
  border: 2px solid black;
  backface-visibility: hidden;
}

.card-first__container-footer {
  width: 560px;
  height: 350px;
  position: absolute;
  background: white;
  border: 2px solid black;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.header__card {
  width: 100%;
  height: 67px;
  right: 0px;
  top: 0px;
  position: absolute;
  background: #002544;
}
.footer__card {
  width: 100%;
  height: 70px;

  bottom: 0px;
  position: absolute;
  background: #002544;
}
.logo-letras-policia__card {
  width: 196.78px;
  top: 3px;
  position: absolute;
}

.logo-letras-policia-footer__card {
  width: 140px;
  margin: 0;
  padding: 0;
}

.escudo-provincia__card {
  width: 47px;
  right: 245px;
  position: absolute;
}

.escudo-provincia-footer__card {
  width: 57px;
  left: 34px;
  top: -28px;
  position: absolute;
}
.logo-secretaria__card {
  width: 200px;
  right: 7px;
  top: 10px;
  position: absolute;
}

.logo-secretaria-footer__card {
  width: 260px;
  right: 7px;
  top: 10px;
  position: absolute;
}

.background-photo__card {
  width: 186px;
  height: 252px;
  left: 19px;
  top: 76px;
  position: absolute;
  background: rgb(217, 217, 217);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  padding: 20px 10px;
}
.contain-img-escudo-policia-footer__card {
  width: 100px;
  position: absolute;
  right: 10px;
  top: 5px;
}
.background-photo-footer__card {
  width: 178px;
  height: 230px;
  left: 19px;
  top: 10px;
  position: absolute;
  background: #d9d9d9;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 10px;
}
.img-policia__card {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.contain-img-policia__card {
  width: 100%;
  height: 168px;
  overflow: hidden;
}

.contain-img-policia-footer__card {
  width: 140px;
  height: 140px;
  overflow: hidden;
}

.contain-info__card {
  width: 332px;
  height: 261px;

  position: absolute;
  left: 216px;
  top: 78px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contain-info-footer__card {
  width: 240px;
  height: auto;
  position: absolute;
  left: 216px;
  top: 23px;
  display: flex;
  flex-direction: column;
}
.zona-info__card {
  display: flex;
  flex-direction: column;
  font-family: MyFont, sans-serif;
}

.zona-info-footer__card {
  display: flex;
  flex-direction: column;
  font-family: MyFont, sans-serif;
  gap: 8px;
}

.titulos-card {
  width: 100%;

  color: black;
  font-size: 18px;

  font-weight: 700;
  overflow-wrap: break-word;
}
.jerarquia-card {
  width: 100%;

  color: black;
  font-size: 15px;
  font-family: MyFont, sans-serif;
  font-weight: 700;
  overflow-wrap: break-word;
}

.contain-detalle-info__card {
  width: 100%;
}

.contain-detalle-info-footer__card {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.titulo-detalle-info__card {
  color: black;
  font-size: 15px;
  font-family: "MyFont", sans-serif;
  font-weight: 380;
  word-wrap: break-word;
}
.subtitulo-detalle-info__card {
  color: black;
  font-size: 18px;
  font-family: "MyFont", sans-serif;

  font-weight: 700;
  word-wrap: break-word;
}
</style>
