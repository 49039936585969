<template>
  <div>
    <AppHeader :breadcrumbsItems="items" :title="'Inicio'" />

    <v-row>
      <v-col cols="12" md="5">
        <v-card
          elevation="2"
          class="d-flex justify-space-between align-center rounded-lg overflow-hidden"
        >
          <div>
            <v-card-title class="pb-0"> {{ saludo }} </v-card-title>

            <v-card-text class="text-h6">
              {{ user?.nombre_completo }}
            </v-card-text>
          </div>
          <div>
            <div class="bubble-shape-sm-secondary bubble-secondary"></div>
            <v-avatar class="ma-3" size="100" tile>
              <v-icon size="100">mdi-account-check</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <AppAsistencia />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppHeader from "../components/dashboard/AppHeader.vue";
import AppAsistencia from "@/components/dashboard/AppAsistencia.vue";
import { mapState } from "vuex";

export default {
  name: "HomeView",
  components: {
    AppHeader,
    AppAsistencia,
    // AppDescarga,
  },
  data() {
    return {
      datosUser: "",
      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Inicio ",
          disabled: false,
          link: false,
        },
      ],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user.userData,
    }),
    saludo() {
      const hora = new Date().getHours();
      if (hora >= 5 && hora < 14) {
        return "Buenos días!";
      } else if (hora >= 14 && hora < 20) {
        return "Buenas tardes!";
      } else {
        return "Buenas noches!";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bubble-shape-sm-secondary.bubble-secondary:before {
  background: linear-gradient(
    98deg,
    rgb(255, 193, 7) -14.02%,
    rgba(144, 202, 249, 0) 77.58%
  );
}
.bubble-shape-sm-secondary.bubble-secondary:after {
  background: linear-gradient(
    393deg,
    rgb(255, 193, 7) -50.94%,
    rgba(144, 202, 249, 0) 83.49%
  );
}

.bubble-shape-sm-secondary:before {
  content: "";
  position: absolute;
  width: 230px;
  height: 230px;
  border-radius: 50%;
  top: -160px;
  right: -30px;
}
.bubble-shape-sm-secondary:after {
  content: "";
  position: absolute;
  width: 210px;
  height: 210px;
  border-radius: 50%;
  top: -57px;
  right: -95px;
}
</style>
