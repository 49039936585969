import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AndroidDownloadView from "../views/AndroidDownloadView";
import AppleDownloadView from "../views/AppleDownloadView";
import TutorialsView from "../views/TutorialsView";
import CredencialPolicial from "@/views/CredendialPolicialView.vue"
import AdminServicios from "@/views/AdminServiciosView.vue"


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginPage.vue"),
  },
  {
    path: "/android",
    name: "androidDownload",
    component: AndroidDownloadView,
  },
  {
    path: "/apple",
    name: "appleDownload",
    component: AppleDownloadView,
  },
  {
    path: "/credencial",
    name: "credencial",
    component: CredencialPolicial,
  },
  {
    path: "/tutoriales",
    name: "tutorials",
    component: TutorialsView,
  }, {
    path: "/admin-servicios",
    name: "admin-servicios",
    component: AdminServicios,
  },
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
