<template>
  <v-card class="mx-auto">
    <v-img class="white--text align-end" max-height="350px" :src="img">
      <!-- <v-card-title>Top 10 Asustralian beache</v-card-title> -->
    </v-img>

    <!-- <v-card-subtitle class="pb-0"> App SICS </v-card-subtitle> -->
    <v-card-title class="pt-1 pb-2">
      {{ title }}
    </v-card-title>
    <v-card-text class="text--primary">
      <div>Recueda seguir las instrucciones para su instalacion.</div>
      <div>
        <strong>Importante:</strong> "Se permite una descarga por usuario"
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="primary"
        block
        :disabled="isDisabled"
        @click="descargarApp()"
      >
        {{ buttonText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "DownloadCard",
  props: {
    link: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "error ",
    },
    img: {
      type: String,
      default: " ",
    },
  },
  methods: {
    descargarApp() {
      // Redirige al enlace de descarga
      window.location.href = this.link;
    },
  },
  computed: {
    isDisabled() {
      return this.link === "";
    },
    buttonText() {
      return this.isDisabled ? "proximamente" : "descargar";
    },
  },
};
</script>

<style lang="scss" scoped></style>
