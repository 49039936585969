<template>
  <div>
    <AppHeader :breadcrumbsItems="items" :title="'Instructivos y Tutoriales'" />
    <v-row>
      <v-col cols="12" md="4" v-for="(app, i) in apps" :key="i">
        <AppDescarga
          :title="app.title"
          :videoId="app.videoId"
          :text="app.text"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppDescarga from "@/components/TutorialCard.vue";
import AppHeader from "../components/dashboard/AppHeader.vue";

export default {
  name: "DownloadView",
  components: {
    AppHeader,
    AppDescarga,
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          link: false,
        },
        {
          text: "Tutoriales",
          disabled: false,
          link: false,
        },
      ],
      apps: [
        {
          title: "Axon Capture",

          videoId: "YVxRFdwTr0M",
          text: "Domina al Maximo Axon",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
