<template>
  <div>
    <AppHeader :breadcrumbsItems="items" :title="'Acreditaciones'" />
    <v-row>
      <v-col>
        <v-card>
          <v-row class="ma-1">
            <!-- <v-col cols="12" md="4" class="pb-0 mb-0">
              <v-autocomplete
                class="pb-0 mb-0"
                single-line
                shaped
                hide-details
                dense
                v-model="sectorial"
                :items="getSectoriales"
                item-text="dataText"
                item-value="dataValue"
                label="Sectorial"
                outlined
                color="primary"
              />
            </v-col>
            <v-col cols="12" md="4" class="pt-1 mt-1 pb-1 mb-1">
              <v-autocomplete
                class="pt-0 mt-0 pb-0 mb-0"
                single-line
                hide-details
                dense
                v-model="destino"
                :items="filteredDestinos"
                item-text="dataText"
                item-value="dataValue"
                label="Destino"
                outlined
                color="primary"
              />
            </v-col>
            <v-col cols="12" md="4" class="pt-1 mt-0 pb-1 mb-1">
              <v-autocomplete
                class="pt-0 mt-0 pb-0 mb-0"
                single-line
                hide-details
                dense
                v-model="jerarquia"
                :items="getJerarquias"
                item-text="dataText"
                item-value="dataValue"
                label="Jerarquía"
                outlined
                color="primary"
              />
            </v-col> -->
            <v-col cols="12" md="4" class="rounded-b-xl mt-0 pt-0">
              <v-text-field
                class="rounded-b-xl mt-0 pt-0"
                dense
                outlined
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
              <v-btn
                class="mt-2"
                color="error"
                block
                small
                @click="limpiarFiltros"
              >
                Limpiar filtros
              </v-btn>
              <v-btn
                class="mt-2"
                color="primary"
                block
                small
                @click="actualizarTabla"
              >
                Actualizar registros
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="filteredPolicias"
            v-if="!loading"
          >
            <template v-slot:[`item.entrada`]="{ item }">
              <v-chip class="ma-2" color="green" outlined>
                {{ parseDate(item.entrada) }}
              </v-chip>
            </template>
            <template v-slot:[`item.salida`]="{ item }">
              <v-chip class="ma-2" color="red" outlined>
                {{ parseDate(item.salida) || "sin marcar" }}
              </v-chip>
            </template>
          </v-data-table>
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
            size="40"
            class="my-5 mx-auto d-block pb-5"
          ></v-progress-circular>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppHeader from "../components/dashboard/AppHeader.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "AdminServiciosView",
  components: {
    AppHeader,
  },
  created() {
    // this.$store.dispatch("dropdownData/getSectoriales");
    // this.$store.dispatch("dropdownData/getDestinos");
    // this.$store.dispatch("dropdownData/getJerarquias");
    this.actualizarTabla(); // Llamamos al cargar la vista
  },
  data() {
    return {
      loading: false, // Estado de carga
      search: "",
      headers: [
        { text: "Nombre y Apellido", value: "visitante.nombre" },
        { text: "D.N.I", value: "visitante.dni" },
        { text: "Entrada", value: "entrada" },
        { text: "Salida", value: "salida" },
      ],
      policias: [],
      items: [
        { text: "Dashboard", disabled: false, link: false },
        { text: "Asistencias", disabled: false, link: false },
      ],
      sectorial: null,
      destino: null,
      jerarquia: null,
    };
  },
  computed: {
    ...mapGetters("dropdownData", [
      "getDestinos",
      "getSectoriales",
      "getJerarquias",
    ]),

    filteredDestinos() {
      if (!this.sectorial) {
        return this.getDestinos;
      }
      return this.getDestinos.filter(
        (destino) => destino.id_sectorial === this.sectorial
      );
    },

    filteredPolicias() {
      return this.policias.filter((policia) => {

        const matchesSearch = this.search
          ? policia.visitante.nombre.toLowerCase().includes(this.search.toLowerCase()) ||
            policia.visitante.dni.includes(this.search)
          : true;
        return (
          matchesSearch
        );
      });
    },
  },
  methods: {
    limpiarFiltros() {
      this.sectorial = null;
      this.destino = null;
      this.jerarquia = null;
      this.search = "";
    },
    async actualizarTabla() {
      this.loading = true; // Inicia el loader
      await this.getAsistencias(); // Obtiene los datos
      this.loading = false; // Finaliza el loader
    },
    parseDate(date) {
      return date ? moment(String(date)).format("DD-MM-YY | HH:mm") : "";
    },
    async getAsistencias() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/asistencias`
        );
        this.policias = response.data;
        console.log("Asistencias:", response);
      } catch (error) {
        console.error("Error al obtener asistencias:", error);
      }
    },
  },
  watch: {
    sectorial() {
      // Cuando se cambia el sectorial, reiniciar el destino
      this.destino = null;
    },
  },
};
</script>

<style lang="scss">
.v-data-table-header-mobile {
  display: none !important;
}
</style>
