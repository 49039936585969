import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import LottieFiles from "vue-lottie";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import VueYouTubeEmbed from "vue-youtube-embed";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAKmdKWIZ_Ba8gGTjeJeQG6XlCl5rjTiLE",
  authDomain: "sics-a8bdd.firebaseapp.com",
  projectId: "sics-a8bdd",
  storageBucket: "sics-a8bdd.appspot.com",
  messagingSenderId: "110962168631",
  appId: "1:110962168631:web:d247a3ea8d2e08126d00b2",
  measurementId: "G-SCS28522YB",
};

// Initialize Firebase
initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
Vue.config.productionTip = false;
Vue.use(VueToast);
Vue.use(VueYouTubeEmbed);
Vue.component("LottieFiles", LottieFiles);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
